<template>
    <template v-if="context.parent && context.parent.tag==='subForm'">
      <!-- 日期格式 -->
      <el-date-picker v-if="type==='fill' && element.attrs.format!=='HH:mm'" placeholder="请设置"
                      v-model="data[element.id]"
                      :type="getType"
                      :format="element.attrs.format" :value-format="element.attrs.format" style="width: 100%">
      </el-date-picker>
      <!-- 时间格式 -->
      <el-time-picker v-else-if="type==='fill' && element.attrs.format==='HH:mm'"  v-model="data[element.id]" placeholder="请设置"
                      :format="element.attrs.format" :value-format="element.attrs.format" style="width: 100%"/>

      <div v-else-if="type==='detail'">{{data[element.id]}}</div>
    </template>

  <el-form-item v-else :required="context.authentication.isRequired(element)">
    <template #label>
      <span :style="{color:'#344262','font-weight':context.form.setting.style.PC.title}">
        {{element.label}}
        <form-element-tooltip :element="element"></form-element-tooltip>
      </span>
    </template>
    <!-- 日期格式 -->
    <el-date-picker v-if="type==='fill' && element.attrs.format!=='HH:mm'" placeholder="请设置"
                    v-model="data[element.id]"
                    :type="getType"
                    :format="element.attrs.format" :value-format="element.attrs.format" style="width: 100%">
    </el-date-picker>
    <!-- 时间格式 -->
    <el-time-picker v-else-if="type==='fill' && element.attrs.format==='HH:mm'"  v-model="data[element.id]" placeholder="请设置"
                    :format="element.attrs.format" :value-format="element.attrs.format" style="width: 100%"/>

    <div v-else-if="type==='detail'" class="design-detail">{{data[element.id] || "⠀"}}</div>
  </el-form-item>
</template>

<script>

import {mapStores} from "pinia";
import {useFlowStore} from "@/stores/flow";
import FormElementTooltip from "@/components/common/formElementTooltip.vue";
import {dynamicFunction} from "@/pojo/script";

export default {
  components: {FormElementTooltip},
  props: {
    context: {
      type: Object,
      default: () => {
      }
    },
    element: {
      type: Object,
      default: () => {
      }
    }
  },
  computed: {
    ...mapStores(useFlowStore),
    getType() {
      switch (this.element.attrs.format) {
        case 'YYYY-MM-DD':
          return 'date';
        case 'YYYY-MM':
          return 'month';
        case 'YYYY':
          return 'year';
        case 'YYYY-MM-DD HH:mm':
          return 'datetime';
      }

      return '';
    }
  },
  data() {
    return {
      data: {},
      type: '',
    }
  },
  created() {
    this.data = this.context.data;
    this.type = this.flowStore.typeOfElement(this.element, this.context);
  },
  mounted() {
    this.initWatch();
  },
  methods:{
    initWatch() {

      if (this.type === 'fill' && this.element.default.select === 'script' && this.element.default.script.value) {

        try {
          this.data[this.element.id] = dynamicFunction(this.element.default.script.value, {...this.data,...this.context.rawData});
        } catch (e) {
          console.error(e);
        }



        if (this.context.rawData) {
          Object.keys(this.context.rawData).forEach(key => {
            if (this.element.default.script.value.includes(key)) {

              this.$watch(`context.rawData.${key}`, (newValue, oldValue) => {
                try {
                  this.data[this.element.id] = dynamicFunction(this.element.default.script.value, {...this.data,...this.context.rawData});
                } catch (e) {

                }
              }, {
                deep: true,
                immediate: false
              });
            }
          });
        }


        Object.keys(this.data).forEach(key => {

          if (this.element.default.script.value.includes(key)) {

            this.$watch(`data.${key}`, (newValue, oldValue) => {
              try {
                this.data[this.element.id] = dynamicFunction(this.element.default.script.value, {...this.data,...this.context.rawData});
              } catch (e) {

              }
            }, {
              deep: true,
              immediate: false
            });

          }
        })


      }
    },
  }
}

</script>

<style scoped lang="scss">

</style>