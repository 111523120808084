

<template>


  <div>
    <div style="font-size: 16px;font-weight: bold;margin-top: 20px">{{ element.label }}</div>
    <el-divider></el-divider>
  </div>


</template>

<script>
export  default {
  props: {
    context: {
      type: Object,
      default: () => {
      }
    },
    element: {
      type: Object,
      default: () => {
      }
    },
  },
  data() {
    return {
    }
  },

}

</script>

<style scoped lang="scss">


</style>