<template>

  <template v-if="context.parent && context.parent.tag==='subForm'">
    <div v-if="type==='fill'" style="width: 100%">
      <el-upload :auto-upload="false" :on-change="onchange" :show-file-list="false" style="padding-bottom: 10px">
        <template #trigger>
          <el-button icon="Camera" style="color: #191E25;font-weight: normal">识别填充</el-button>
        </template>
      </el-upload>
    </div>
    <div v-else-if="type==='detail'">⠀</div>
  </template>

  <el-form-item v-else :label="element.label" :required="context.authentication.isRequired(element)">
    <template #label>
      <span :style="{ color:'#344262','font-weight':context.form.setting.style.PC.title}">
        {{ element.label }}
        <form-element-tooltip :element="element"></form-element-tooltip>
      </span>
    </template>
    <div v-if="type==='fill'" style="width: 100%">

      <el-upload :auto-upload="false" :on-change="onchange" :show-file-list="false" style="padding-bottom: 10px">
        <template #trigger>
          <el-button icon="Camera" style="color: #191E25;font-weight: normal">识别填充</el-button>
        </template>
        <div style="font-size: 12px;color: #8A94A5;line-height: 16px;margin-top: 10px">
          请确保识别图片朝向正确、清晰、不反光、不过曝和不欠曝（亮度过低）
        </div>
      </el-upload>
    </div>

    <div v-else class="design-detail">⠀</div>

  </el-form-item>


</template>

<script>

import {mapStores} from "pinia";
import {useFlowStore} from "@/stores/flow";
import {useOcrStore} from "@/stores/ocr";
import {useFormStore} from "@/stores/form";
import {useAttachmentStore} from "@/stores/attachment";
import FormElementTooltip from "@/components/common/formElementTooltip.vue";
import {ElLoading} from 'element-plus'
import * as imageConversion from "image-conversion";

export default {
  components: {FormElementTooltip},
  props: {
    element: {
      type: Object,
      default: () => {
      }
    },
    context: {
      type: Object,
      default: () => {
      }
    },
    fileFormat: {
      type: Array,
      default: () => ['png', 'jpg']
    },
    fileSize: {
      type: Number,
      default: () => 4
    },
    imageSize: {
      type: Number,
      default: () => 4
    },
  },
  data() {
    return {
      data: {},
      type: '',
      token: '',
      operation: ''
    }
  },
  computed: {
    ...mapStores(useFlowStore, useOcrStore, useAttachmentStore, useFormStore)
  },
  created() {
    this.data = this.context.data;
    this.ocrStore.getToken().then(res => this.token = res.data);
    this.operation = this.element.attrs.operation;
    this.type = this.flowStore.typeOfElement(this.element, this.context);

  },
  mounted() {

  },
  methods: {
    onchange(file) {

      let file_format_check = false;
      for (let i = 0; i < this.fileFormat.length; i++) {
        if (file.name.toLowerCase().endsWith(this.fileFormat[i])) {
          file_format_check = true;
          break;
        }
      }

      if (!file_format_check) {
        this.message(`上传仅支持 ${this.fileFormat.toString().toUpperCase()} 格式`, 'error');
        return;
      }
      if (!(file.size / 1024 / 1024 < this.fileSize)) {
        this.message('上传文件大小不能超过 ' + this.fileSize + 'MB', 'error');
        return;
      }

      let loading = ElLoading.service({
        lock: true,
        text: '正在识别，请稍候',
        background: 'rgba(0, 0, 0, 0.7)',
      })


      this.$nextTick(() => {
        if (file.name.toLowerCase().endsWith('png') || file.name.toLowerCase().endsWith('jpg') || file.name.toLowerCase().endsWith('jpeg')) {
          imageConversion.compressAccurately(file.raw, {
            size: this.imageSize * 1024,
            type: file.type,
            scale: Math.min(1 / (file.size / parseInt(this.imageSize * 1024 * 1024)), 1),
          }).then(async (res) => {
            let imageBase64 = await this.blobToBase64(res);
            this.ocrStore.identify(this.token, imageBase64, this.element.attrs.type).then(res => {


              if (!res.words_result) {
                this.confirm('<div>图片目标识别错误，请确保图片中包含对应卡证票据，出现此问题的原因一般为：您上传了非卡证图片、图片不完整或模糊</div>', 'error')
                loading.close();
                return;
              }

              let source = res.words_result || res.result;
              let target = this.data;

              let imageOperation = this.operation.find(find => find.name === '识别图片');
              if (imageOperation && imageOperation.to) {
                imageConversion.compressAccurately(file.raw, {
                  size: 0.3 * 1024,
                  type: file.type,
                  scale: Math.min(1 / (file.size / parseInt(0.3 * 1024 * 1024)), 1),
                }).then(imageBlob => {
                  this.attachmentStore.uploadImage(imageBlob, {
                    filename: file.name,
                    application: this.context.form.application_id,
                    menu: this.context.form.id
                  }).then((res) => {
                    imageOperation.from = imageOperation.to;
                    source[imageOperation.from] = res.data;
                    let columns = this.formStore.columnsFlatMap(this.context.form);
                    this.ocrStore.fillData(this.element, columns, this.operation, source, target);
                    loading.close();
                  })
                })

              } else {
                let columns = this.formStore.columnsFlatMap(this.context.form);
                this.ocrStore.fillData(this.element, columns, this.operation, source, target);
                loading.close();
              }
            })
          })
        }
      });
    },
  }
}

</script>

<style scoped lang="scss">

</style>