<template>
  <div class="group-container">
  <div class="group-header">
    <div class="group-title">
      <slot name="title">{{ title }}</slot>
    </div>
    <el-icon style="float: right" @click="isVisible=!isVisible">
      <ArrowUp v-if="isVisible"/>
      <ArrowDown v-if="!isVisible"/>
    </el-icon>
  </div>
  <el-divider></el-divider>
  <slot v-if="isVisible"></slot>
  </div>
</template>

<script>
import {ArrowDown, ArrowUp} from "@element-plus/icons-vue";

export default {
  components: {ArrowDown, ArrowUp},
  props: {
    title: String,
  },
  data() {
    return {
      isVisible: true
    }
  },
  methods: {
    visible() {
      return this.isVisible;
    }
  }

}

</script>

<style scoped lang="scss">
.el-divider--horizontal {
  margin: 10px 0 20px 0;
}

.group-container{
  margin-top: 40px;
  margin-bottom: 40px;
}

.group-header {
  display: flex;
  align-items: center
}

.group-title {
  flex: 1;
  font-size: 14px;
  font-weight: bold
}
</style>