<template>

  <el-drawer v-model="drawerVisible" direction="rtl" :size="fullScreen?'100%': '65%'"
             :append-to-body="true" @close="handleClose" :show-close="false">
    <template #header="{  titleId, titleClass,close }">
      <div :id="titleId" :class="titleClass" style="font-size: 16px;font-weight: bold;color: rgb(48,66,101)">
        <span v-if="form"> {{ form.name }} - {{ title }} </span>
      </div>

      <span>

      <el-button v-prevent-re-click
                 :icon="fullScreen?'Minus':'FullScreen'" type="info" link
                 style="font-size: 16px;"
                 @click="fullScreen=!fullScreen"></el-button>

      <el-button v-prevent-re-click icon="Close" type="info" link style="font-size: 20px;"
                 @click="close()"></el-button>
      </span>


    </template>
    <template #default>
      <div v-loading="loading" class="form-container">
        <el-form v-if="form" ref="form" :model="data" :label-position="form.setting.style.PC.layout" label-width="auto">
          <template v-for="(element,index) in form.elements" :key="index">
            <component v-if="authentication.isRead(element)" :ref="element.id"
                       v-show="authentication.isHide(element,data)"
                       :is="`design${element.tag.charAt(0).toUpperCase() + element.tag.slice(1)}Preview`"
                       :element="element"
                       :context="{ form:form, data, parent:null,authentication}">
            </component>
          </template>
        </el-form>
      </div>
    </template>

    <template #footer>
      <div style="text-align: right">
        <el-button v-prevent-re-click type="primary" @click="submit()" style="width: 80px" :disabled="!form" dark>提
          交
        </el-button>
      </div>
    </template>

  </el-drawer>

</template>

<script>
import {mapStores} from "pinia";
import {useFormStore} from "@/stores/form";
import {useFormAuthenticationStore} from "@/stores/form-authentication";
import {useMenuStore} from "@/stores/menu";

export default {
  emits: ['callback'],
  computed: {
    ...mapStores(useFormStore, useMenuStore, useFormAuthenticationStore),
  },
  data() {
    return {
      drawerVisible: false,
      data: {},
      title: '新增',
      fullScreen: false,
      loading: false,
      //字段权限
      authentication: null,
      form: null,
    }
  },
  methods: {
    call(formId,data) {
      if (!formId) {
        this.notify('消息', '无法获取表单id')
        return;
      }
      this.drawerVisible = true;
      this.$nextTick(() => {
        this.loading = true;
        this.menuStore.findById(formId).then(response => {
          let form = response.data;
          if (data) {
            this.data = data;
            this.title = '编辑'
            this.authentication = this.formAuthenticationStore.edit(form);
          } else {
            this.data = this.formStore.initData(this.deepClone(form))
            this.authentication = this.formAuthenticationStore.add(form);
          }
          this.form = response.data;
          this.loading = false;
        })
      })
    },
    submit() {

      const errorCallback = (message, field) => {
        this.message(message, 'error');
        this.loading = false;
        try {
          this.$refs[field][0].$el.firstElementChild.focus()
        } catch (e) {

        }

      }

      if (this.data.id) {
        this.authentication.validate(this.data, () => {
          this.loading = true;
          this.formStore.updateRow(this.form.id, this.data).then(res => {
            this.loading = false;
            this.drawerVisible = false;
            this.$emit('callback')
          })
        }, errorCallback);

      } else {
        this.authentication.validate(this.data, () => {
          this.loading = true;
          this.formStore.postRow(this.form.id, this.data).then(() => {
            this.loading = false;
            this.drawerVisible = false;
            this.$emit('callback');
          })
        }, errorCallback);
      }
    },
    handleClose() {
      this.data = {};
      this.authentication = null;
      this.form = null;
    }
  }


}

</script>

<style lang="scss">


.el-drawer {
  background-color: #F5F7FA;

  .btn-full-screen {
    margin-right: 10px;
    padding: 2px 6px 3px 6px;
    font-weight: normal;
    color: #73767A
  }

  .form-container {
    background-color: white;
    padding: 0 20px 20px 20px;
    min-height: 100%;
    border-radius: 8px;

    .el-form {
      height: 100%;
      background-color: white;
      padding: 24px 20px 0 20px;
      border-radius: 10px
    }
  }
}

</style>