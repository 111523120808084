import {defineStore} from 'pinia'
import {useAppStore} from "@/stores/app";
import userController from "@/controller/userController";

export const useUserStore = defineStore('user', {
    state: () => {
        return {
            token: '',
            user: {},
        }
    },
    actions: {
        findById(id) {
            return userController.findById({id});
        },
        findByIds(ids) {
            return userController.findByIds({ids: ids.join(",")});
        },
        setToken(token) {
            this.token = token;
        },
        setUser(user) {
            this.user = user;
        },
        setCompanies(companies) {
            this.user.companies = companies;
        },
        setCompanySelected(company_selected) {
            this.user.company_selected = company_selected;
        },
        switchCompany(company_id) {
            userController.switchCompany(company_id).then(({data}) => {
                this.setUser(data)
                location.reload();
            });
        },
        icon(icon, isRefresh) {
            let appStore = useAppStore();
            if (!isRefresh) {
                return icon ? appStore.resourceUrl(`/static/image/${icon}`) : appStore.resourceUrl('/static/image/head/default.png');
            } else {
                return icon ? appStore.resourceUrl(`/static/image/${icon}?arg=${new Date().getTime()}`) : appStore.resourceUrl('/static/image/head/default.png');
            }
        },
        async list(keyword, dept_id) {
            return await userController.list({keyword, company_id: this.user.company_selected, dept_id});
        },
        async listByDept(dept_id) {
            return await userController.listByDept({dept_id, company_id: this.user.company_selected});
        },
        async listByRole(role_id) {
            return await userController.listByRole({role_id, company_id: this.user.company_selected});
        },
        listByAccountOrCellphone(keyword) {
            return userController.listByAccountOrCellphone({keyword});
        },
        async unbindCompany(id) {
            return await userController.unbindCompany({id});
        },
        async bindCompany(id) {
            return await userController.bindCompany({id});
        },
        async put(user) {
            return await userController.put(user);
        },
        async post(user) {
            user.companies = [this.user.company_selected];
            return await userController.post(user);
        },
        uploadAvatar(data, params) {
            return userController.uploadAvatar(data, params);
        },
        logout() {
            this.token = ''
            this.user = {};
            window.location.href = "/#/login";
        },
        isRole(role) {
            return this.getRoles.includes(role)
        },
        isDept(dept) {
            return this.getDepts.includes(dept)
        },
        isUser(user) {
            return this.user.id === user;
        }
    },
    getters: {
        getId() {
            return this.user.id;
        },
        getToken() {
            return this.token;
        },
        getUser() {
            return this.user;
        },
        getRoles() {
            try{
                return this.user.roles[this.user.company_selected] || [];
            }catch (e){
                return  [];
            }

        },
        getDepts() {
            return this.user.depts[this.user.company_selected] || [];
        },
        getCompanies() {
            return this.user.companies;
        },
        getCompanySelected() {
            return this.user.company_selected;
        },
        root() {
            return this.user.root;
        }
    },
    persist: {
        enabled: true
    }
})