import {defineStore} from 'pinia'
import menuController from "@/controller/menuController";
import {useUserStore} from "@/stores/user";
import {ElNotification} from 'element-plus'

export const useMenuStore = defineStore('menu', {
    state: () => {
        return {}
    },
    actions: {
       async list(types, application_id) {
            return await menuController.list({types, application_id});
        },
        async findById(id) {
            return await menuController.findById(id);
        },
        delete(id) {
            return menuController.delete(id);
        },
        groups(application_id) {
            return menuController.groups({application_id});
        },
        /**
         * 表单新增请使用 useFormStore#sumbit,此方法只针对除表单外类型
         * @param data
         * @param callback
         */
        submit(data, callback) {
            menuController.post(data).then(res => {
                if (callback) {
                    callback()
                }
            });
        },
    },
    getters: {},
    persist: {
        enabled: false
    }
})