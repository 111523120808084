<template>
  <div v-loading="loading">
    <div style="display: flex;align-items: self-start">
      <el-avatar :src="userStore.icon(user.icon)" :size="30"></el-avatar>
      <el-input type="textarea" v-model="content" placeholder="发布你的评论" resize="none"
                :autosize="{ minRows: 1, maxRows: 4}" style="border-radius: 40px;flex: 1;margin-left: 10px"
                size="large">
        <template #append>
          <el-button :icon="Search"/>
        </template>
      </el-input>
    </div>
    <p style="text-align: right;">
      <el-button type="primary" round style="width: 60px;font-weight: normal;font-size: 13px;height: 28px"
                 @click="publish" size="small">发布
      </el-button>
    </p>

    <div class="yhpl" v-for="(item,index) in comment" :key="index">
      <div class="yhpl-info">
        <el-avatar :src="userStore.icon(item.who.icon)" :size="32"></el-avatar>
        <div class="yhpl-bd">
          <div class="yhpl-name">{{ item.who.name }}</div>
          <div class="yhpl-time">{{ getDateDiff(item) }}</div>
        </div>
      </div>
      <div class="yhpl-main">
        {{ item.content }}
      </div>
      <div class="yhpl-plhf-title" @click="readyReply(item)">
        <el-icon :size="14" style="margin-right: 4px">
          <ChatDotSquare/>
        </el-icon>
        评论
      </div>

      <div v-if="item.showReply" style="padding-top: 10px">
        <div style="display: flex;align-items: self-start">
          <el-avatar :src="userStore.icon(user.icon)" :size="30"></el-avatar>
          <el-input type="textarea" v-model="item.reply" placeholder="发布你回复" resize="none"
                    :autosize="{ minRows: 1, maxRows: 4}"
                    style="border-radius: 40px;flex: 1;margin-left: 10px"></el-input>
        </div>
        <p style="text-align: right;">
          <el-button @click="item.showReply=false" link style="font-weight: normal;font-size: 13px" size="small">取消
          </el-button>
          <el-button type="primary" @click="reply(item)" round style="font-weight: normal;font-size: 13px" size="small">
            回复
          </el-button>
        </p>
      </div>

      <div class="yhpl-plhf" v-if="item.children.length>0">
        <div class="yhpl-plhf-itme" v-for="(child,child_index) in item.children" :key="child_index">
          <span class="plhf-name">{{ child.who.name }}</span>
          <span>&nbsp;回复&nbsp;</span>
          <!--           <span class="plhf-name">{{item.who.name}}</span>-->
          <span class="plhf-main">:{{ child.content }}</span>
          <span class="plhf-time">{{ getDateDiff(child) }}</span>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import {mapStores} from "pinia";
import {useUserStore} from "@/stores/user";
import {useFormStore} from "@/stores/form";
import userController from '@/controller/userController.js'
import formController from '@/controller/formController.js'

export default {
  props: {
    form_id: {
      type: String,
      default: () => ""
    },
    data_id: {
      type: String,
      default: () => ""
    },
  },
  computed: {
    ...mapStores(useUserStore, useFormStore)
  },
  mounted() {
    this.user = this.userStore.user;
    this.loading = true;
    this.formStore.getComment(this.form_id, this.data_id).then(({data}) => {
      this.comment = data;
      this.comment.sort((o1, o2) => new Date(o2.time).getTime() - new Date(o1.time).getTime())
      this.loading = false;
    })
  },
  data() {
    return {
      comment: [],
      content: '',
      loading: false,
      user: {}
    }
  },
  methods: {
    readyReply(item) {
      item.showReply = true
    },
    reply(item) {

      if (!item.reply)
        return;

      this.loading = true

      userController.getSession().then(res => {
        let user = res.data.user
        item.children.push({
          who: {
            id: user.id,
            name: user.real_name,
            icon: user.icon
          },
          content: item.reply,
          time: this.current(),
        })
        let params = this.deepClone(this.comment);
        formController.comment(this.form_id, this.data_id, params).then(res => {
          item.showReply = false
          item.reply = ""
          this.loading = false
        })
      })

    },
    publish() {
      if (!this.content) return;

      this.loading = true
      userController.getSession().then(res => {
        let user = res.data.user
        let c = {
          who: {
            id: user.id,
            name: user.real_name,
            icon: user.icon
          },
          content: this.content,
          time: this.current(),
          children: [],
          showReply: false,
          reply: ""
        }
        let params = this.deepClone(this.comment);
        params.push(c)

        formController.comment(this.form_id, this.data_id, params).then(res => {
          this.content = ""
          let arr = this.comment
          arr.push(c);
          this.loading = false
        })
      })
    },
    getDateDiff(item) {

      let dateTimeStamp = new Date(item.time).getTime();
      // console.log(dateTimeStamp)
      let result = '';
      let minute = 1000 * 60;
      let hour = minute * 60;
      let day = hour * 24;
      let halfamonth = day * 15;
      let month = day * 30;
      let year = day * 365;
      let now = new Date().getTime();
      // console.log(now)
      let diffValue = now - dateTimeStamp;
      // console.log(diffValue)
      if (diffValue < 0) {
        return;
      }
      let monthEnd = diffValue / month;
      let weekEnd = diffValue / (7 * day);
      let dayEnd = diffValue / day;
      let hourEnd = diffValue / hour;
      let minEnd = diffValue / minute;
      let yearEnd = diffValue / year;
      if (yearEnd >= 1) {
        result = item;
        // result = "" + parseInt(yearEnd) + "年前";

      } else if (monthEnd >= 1) {
        result = "" + parseInt(monthEnd) + "月前";
      } else if (weekEnd >= 1) {
        result = "" + parseInt(weekEnd) + "周前";
      } else if (dayEnd >= 1) {
        result = "" + parseInt(dayEnd) + "天前";
      } else if (hourEnd >= 1) {
        result = "" + parseInt(hourEnd) + "小时前";
      } else if (minEnd >= 1) {
        result = "" + parseInt(minEnd) + "分钟前";
      } else {
        result = "刚刚";
      }

      return result;
    },
  }
}
</script>

<style scoped lang="scss">
.yhpl {
  margin-bottom: 15px;
  border-bottom: 1px solid #EEEEEE;
  padding-bottom: 15px;
}

.yhpl-info {
  display: flex;
}

.yhpl-info .yhpl-bd {
  flex: 1;
  margin-left: 10px;
}

.yhpl-name {
  color: #409EFF;
  font-size: 13px;
  line-height: 18px;
}

.yhpl-time {
  font-size: 12px;
  color: #999;
}

.yhpl-main {
  font-size: 13px;
  margin-top: 10px;
  color: #333;
}

.yhpl-plhf {
  border-left: 2px solid #F3F3F3;
  //background-color: rgb(247, 247, 247);
  padding: 2px;
  margin: 20px 0 10px 0;
}

.yhpl-plhf-itme {
  display: flex;
  align-items: flex-end;
  font-size: 13px;
  margin: 0 6px 6px 6px;
}

.plhf-main {
  color: #333;
  flex: 1;
}

.plhf-name {
  color: #0089ff;
}

.plhf-time {
  color: #999;
}

.yhpl-plhf-title {
  display: flex;
  align-items: center;
  width: 48px;
  cursor: pointer;
  color: #999;
  margin-top: 10px;
  margin-bottom: 8px;
}

.yhpl-plhf-title:hover {
  color: #0089ff
}

:deep( .el-textarea__inner) {
  border-radius: 6px;
  box-shadow: 0 0 0 1px #409EFF inset;
}

</style>
