import {defineStore} from 'pinia'
import {useUserStore} from "@/stores/user";
import {ElMessageBox} from "element-plus";
import flowController from "@/controller/flowController";
import {useFormStore} from "@/stores/form";
import {get} from "@/plugins/axios";

export const useFlowStore = defineStore('flow', {
    state: () => {
        return {}
    },
    actions: {
        hasWorkFlow(params) {
            return flowController.hasWorkFlow(params)
        },
        /* node的属性记录在attrs中，拖拽结束初始化attrs */
        initNodeAttrs(node) {

            let formStore = useFormStore();
            let form = formStore.editor;
            let formColumns = formStore.columns(form)

            switch (node.type) {
                case 'sponsor':
                    node.attrs.operation = this.initOperation(node, formColumns, node.attrs.operation);
                    this.initButtons(node);
                    this.initProcessSequence(node);
                    this.initFlowRule(node);
                    this.initTargetNotFound(node);
                    this.initNotification(node);
                    this.initTimeLimit(node);
                    break;
                case 'handle':
                    node.attrs.operation = this.initOperation(node, formColumns, node.attrs.operation);
                    this.initAgent(node);
                    this.initButtons(node);
                    this.initProcessSequence(node);
                    this.initFlowRule(node);
                    this.initTargetNotFound(node);
                    this.initNotification(node);
                    this.initTimeLimit(node);
                    break;
                case 'approval':
                    node.attrs.operation = this.initOperation(node, formColumns, node.attrs.operation);
                    this.initAgent(node);
                    this.initButtons(node);
                    this.initProcessSequence(node);
                    this.initFlowRule(node);
                    this.initTargetNotFound(node);
                    this.initNotification(node);
                    this.initTimeLimit(node);
                    break;
                case 'copyAndSend':
                    node.attrs.operation = this.initOperation(node, formColumns, node.attrs.operation);
                    this.initAgent(node);
                    this.initNotification(node);
                    break;
            }

        },
        //初始化流程的operation属性
        initOperation(node, columns, operation) {
            let operationNew = {};
            columns.filter(filter => !filter.id.startsWith('_property')).forEach(item => {
                let id = item.id;
                operationNew[id] = {};
                if (node.type === 'sponsor') {
                    operationNew[id].isRead = operation && operation[id] ? operation[id].isRead : true;
                    operationNew[id].isWrite = operation && operation[id] ? operation[id].isWrite : true;
                    operationNew[id].isRequired = operation && operation[id] ? operation[id].isRequired : false;
                    operationNew[id].isPrint = operation && operation[id] ? operation[id].isPrint : false;
                } else {
                    operationNew[id].isRead = operation && operation[id] ? operation[id].isRead : true;
                    operationNew[id].isWrite = operation && operation[id] ? operation[id].isWrite : false;
                    operationNew[id].isRequired = operation && operation[id] ? operation[id].isRequired : false;
                    operationNew[id].isPrint = operation && operation[id] ? operation[id].isPrint : true;
                }
                if (item.id.startsWith('_property')) {
                    delete operationNew[item.id].isWrite;
                    delete operationNew[item.id].isRequired;
                }
            });

            return operationNew;
        },
        initButtons(node) {
            if (node.attrs.buttons !== undefined) {
                return;
            }

            let buttons = [
                {
                    label: '暂存',
                    enable: true,
                    disabled: false,
                    tip: '用于保存用户编辑的数据但停留在当前流程',
                    alias: '',
                },
                {
                    label: '提交',
                    enable: true,
                    disabled: true,
                    tip: '用于保存用户编辑的数据，并根据流程规则触发后续节点',
                    alias: '',
                },
                {
                    label: '转交',
                    enable: true,
                    disabled: false,
                    tip: '用于保存用户编辑的数据，并转交流程给指定人员',
                    alias: '',
                },
                {
                    label: '退回',
                    alias: '',
                    enable: false,
                    disabled: false,
                },

            ];
            if (node.type === 'approval') {
                buttons = [
                    {
                        label: '同意',
                        enable: true,
                        disabled: true,
                        tip: '用于保存用户编辑的数据，并根据流程规则触发后续节点',
                        alias: '',
                        opinion: [
                            {
                                label: '文本意见',
                                required: false,
                                enable: true,
                            },
                            {
                                label: '手写签名',
                                required: false,
                                enable: true,
                            },
                            {
                                label: '图片',
                                required: false,
                                enable: true,
                            },
                            {
                                label: '附件',
                                required: false,
                                enable: true,
                            },
                        ]
                    },
                    {
                        label: '不同意',
                        enable: true,
                        disabled: true,
                        tip: '用于保存用户编辑的数据但停留在当前流程',
                        alias: '',
                        opinion: [
                            {
                                label: '文本意见',
                                required: false,
                                enable: true,
                            },
                            {
                                label: '手写签名',
                                required: false,
                                enable: true,
                            },
                            {
                                label: '图片',
                                required: false,
                                enable: true,
                            },
                            {
                                label: '附件',
                                required: false,
                                enable: true,
                            },]
                    },
                    {
                        label: '转交',
                        enable: true,
                        disabled: false,
                        tip: '用于保存用户编辑的数据，并转交流程给指定人员',
                        alias: '',
                    },
                    {
                        label: '暂存',
                        enable: true,
                        disabled: false,
                        tip: '用于保存用户编辑的数据但停留在当前流程',
                        alias: '',
                    },
                    {
                        label: '退回',
                        alias: '',
                        enable: false,
                        disabled: false,
                    }]
            }

            node.attrs.buttons = buttons;
        },
        initProcessSequence(node) {
            if (node.attrs.processSequence !== undefined) {
                return;
            }
            node.attrs.processSequence = 'simultaneously';
        },
        initTargetNotFound(node) {
            if (node.attrs.targetNotFound !== undefined) {
                return;
            }
            node.attrs.targetNotFound = 'manager';
        },
        initNotification(node) {
            if (node.attrs.notification !== undefined) {
                return;
            }


            node.attrs.notification = {
                enable: true,
                type: 'default', //default / custom
                custom: '',
            }

        },
        initTimeLimit(node) {
            if (node.attrs.timeLimit !== undefined) {
                return;
            }


            node.attrs.timeLimit = 'none';
        },
        initAgent(node) {
            if (node.attrs.agent !== undefined) {
                return;
            }

            node.attrs.agent = [];
        },
        initFlowRule(node) {

            if (node.attrs.flowRule !== undefined) {
                return;
            }

            node.attrs.flowRule = {
                agree: {
                    /**
                     * 按人数： numberOfPeople
                     * 按百分比：percent
                     */
                    select: 'percent',
                    value: 100
                },
                disagree: {
                    select: 'numberOfPeople',
                    value: 1
                },
                reject: {
                    /**
                     * last:驳回到前一个经办节点  /
                     * first:驳回到第一个经办节点
                     * appoint:驳回到指定经办节点
                     * shutdown：直接否决流程且不再流转
                     */
                    select: 'last',
                    /**
                     * 驳回到指定经办节点的id
                     */
                    appoint: null
                }
            }
        },
        closeWorkFlow(callback) {
            ElMessageBox.confirm(
                `流转中的数据将停止流转，流转完成的数据其流程信息将丢失`,
                '关闭流程',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    dangerouslyUseHTMLString: true,
                    type: 'warning',
                    autofocus: false,
                }
            ).then(() => {
                if (callback) callback()
            }).catch(() => {
            })
        },
        /**
         * 结合context 获取字段的形态
         * @param element
         * @param context
         * @returns {string} - fill / detail
         */
        typeOfElement(element, context) {

            if(context.parent && context.parent.tag==='subForm'){
                return context.type;
            }

            if (context.node) {
                //同样是发起节点，发起后将不再可写
                if (context.type === 'sponsor') {
                    try {
                        if (context.data['_property.workFlow_status'] && context.node.attrs.status !== '待处理') {
                            return "detail";
                        }
                    } catch (e) {

                    }
                }
                try {
                    if (context.node.attrs.operation[element.id].isWrite) {
                        return 'fill';
                    } else if (context.node.attrs.operation[element.id].isRead) {
                        return 'detail';
                    }
                } catch (e) {
                    console.error('typeOfElement', element.label, JSON.stringify(context.node, null, 4))
                }
            }


            if (context.authentication) {

                return context.authentication.getType(element);
            }




            return context.type || 'fill';
        },
        /**
         * 查询流程消息数量
         * @param type -  sponsor(我发起的) / approval(待办) / copyAndSend(待阅)
         * @param actor - 非必填， 指定查阅人，默认使用当前用户
         * @param company - 非必填，指定某公司的流程消息，默认所有
         */
        countMessage(type, actor, company) {

            let userStore = useUserStore();
            actor = actor || userStore.user.id;
            company = company || userStore.getCompanySelected;

            return flowController.countMessage({
                type,
                actor,
                company
            })
        },
        /**
         *
         * @param type  - 同#countMessage
         * @param actor  - 同#countMessage
         * @param company - 同#countMessage
         * @returns {Promise | Promise<unknown>}
         */
        listMessage(type, actor, company) {

            let userStore = useUserStore();
            actor = actor || userStore.user.id;
            company = company || userStore.getCompanySelected;

            return flowController.listMessage({
                type,
                actor,
                company
            })

        },
        timeline(formId, dataId) {
            return flowController.timeline(formId, dataId);
        },
        async approval(form_id, data_id, node_id, data, result, opinion) {
            if (!opinion && result === '拒绝') {
                opinion = '不同意';
            }

            return await flowController.approval(form_id, data_id, node_id, data, result, opinion)
        },
        async back(form_id, data_id, node_id, target_id, opinion) {

            return await flowController.back(form_id, data_id, node_id, target_id, opinion)
        },
        async submit(form_id, data_id, node_id, data, opinion) {

            return await flowController.submit(form_id, data_id, node_id, data, opinion)
        },
        /**
         *
         * @param form_id
         * @param data_id
         * @param node_id
         * @param operation  -  通过 / 已取消
         * @param data
         * @returns {Promise | Promise<unknown>}
         */
        async sponsor(form_id, data_id, node_id, operation, data) {
            return await flowController.sponsor(form_id, data_id, node_id, operation, data)
        },
        copyAndSend(form_id, data_id, node_id) {
            return flowController.copyAndSend(form_id, data_id, node_id);
        },
        handle(form_id, data_id, node_id, user, opinion) {
            return flowController.handle(form_id, data_id, node_id, {
                user, opinion
            });
        },
        async getFlowNodeBySession(form_id, data_id) {
            return await flowController.getFlowNodeBySession({form_id, data_id});
        },
        getParentNode(currentNode, nodeList, lineList) {


            let arr = [];
            lineList.filter(item => item.to === currentNode.id).forEach(line => {
                nodeList.filter(node => node.id === line.from && node.type !== 'start').forEach(node => {
                    arr.push(node);
                    arr.push(...this.getParentNode(node, nodeList, lineList));
                })
            })

            return arr;
        }
    },
    persist: {
        enabled: false
    }
})