<template>

    <template v-if="context.parent && context.parent.tag==='subForm'">
      <el-switch v-if="type==='fill'"  v-model="data[element.id]" inline-prompt active-text="是" inactive-text="否" :disabled="context.authentication.isDisabled(element,data)"/>
      <div v-else-if="type==='detail'">
        <el-switch inline-prompt model-value="data[element.id]" active-text="是" inactive-text="否"  />
      </div>
    </template>

  <el-form-item v-else :required="context.authentication.isRequired(element)">
    <template #label>
       <span :style="{color:'#344262','font-weight':context.form.setting.style.PC.title}">
        {{ element.label }}
        <form-element-tooltip :element="element"></form-element-tooltip>
      </span>
    </template>
    <el-switch v-if="type==='fill'"  v-model="data[element.id]" inline-prompt active-text="是" inactive-text="否"
               :disabled="context.authentication.isDisabled(element,data)"/>
    <div v-else-if="type==='detail'" class="design-detail">
      <el-switch inline-prompt :model-value="data[element.id]" active-text="是" inactive-text="否"   />
    </div>
  </el-form-item>
</template>

<script>

import {mapStores} from "pinia";
import {useFlowStore} from "@/stores/flow";
import FormElementTooltip from "@/components/common/formElementTooltip.vue";

export default {
  components: {FormElementTooltip},
  props: {
    context: {
      type: Object,
      default: () => {
      }
    },
    element: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      data: {},
      type: '',
    }
  },
  computed: {
    ...mapStores(useFlowStore)
  },
  created() {
    this.data = this.context.data;
    if (typeof this.data[this.element.id] === 'string') {
      this.data[this.element.id] = false;
    }
    this.type = this.flowStore.typeOfElement(this.element, this.context);
  }
}

</script>

<style scoped lang="scss">

</style>