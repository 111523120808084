<template>

  <template v-if="context.parent && context.parent.tag==='subForm'">
    <multiple-file-upload-list v-if="type==='fill'" v-model="data[element.id]"
                               :params="{application:context.form.application_id,menu:context.form.id}"
                               :file-format="element.attrs.fileTypes?element.attrs.fileTypes:uploadTypes"></multiple-file-upload-list>
    <div v-else-if="type==='detail'" style="width: 100%">
      <div v-if="!data[element.id] || data[element.id].length===0">⠀</div>
      <attachmentItem :value="data[element.id]" background="transparent"></attachmentItem>
    </div>
  </template>

  <el-form-item v-else :required="context.authentication.isRequired(element)">
    <template #label>
      <span :style="{color:'#344262','font-weight':context.form.setting.style.PC.title}">
        {{ element.label }}
        <form-element-tooltip :element="element"></form-element-tooltip>
      </span>
    </template>
    <multiple-file-upload v-if="type==='fill'" v-model="data[element.id]"
                          :file-format="element.attrs.fileTypes?element.attrs.fileTypes:uploadTypes"
                          :params="{application:context.form.application_id,menu:context.form.id}"></multiple-file-upload>
    <div v-else-if="type==='detail'" style="width: 100%">
      <div v-if="!data[element.id] || data[element.id].length===0" class="design-detail">⠀</div>
      <attachmentItem :value="data[element.id]"></attachmentItem>
    </div>

  </el-form-item>
</template>

<script>

import MultipleFileUpload from "@/components/common/multipleFileUpload.vue";
import attachmentItem from "@/components/design-form-list/attachmentItem.vue";
import multipleFileUploadList from "@/components/common/multipleFileUploadList.vue";

import {mapStores} from "pinia";
import {_upload_types} from "@/pojo/data-design-form-aside";
import {useFlowStore} from "@/stores/flow";
import FormElementTooltip from "@/components/common/formElementTooltip.vue";

export default {
  components: {FormElementTooltip, attachmentItem, MultipleFileUpload, multipleFileUploadList},
  props: {
    context: {
      type: Object,
      default: () => {
      }
    },
    element: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      uploadTypes: _upload_types,
      data: {},
      type: '',
    }
  },
  computed: {
    ...mapStores(useFlowStore)
  },
  created() {
    this.data = this.context.data;
    if (typeof this.data[this.element.id] === 'string') {
      this.data[this.element.id] = [];
    }

    this.type = this.flowStore.typeOfElement(this.element, this.context);
  }
}

</script>

<style scoped lang="scss">
:deep(.el-input.is-disabled .el-input__inner) {
  cursor: default;
  background-color: white;
}

:deep(.el-input__wrapper) {
  padding: 1px 1px;
}
</style>